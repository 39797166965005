// UsageInstructions.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

import "./UsageInstructions.css";

import modepng from "./assets/img/instructions/modes.png"
import mainmenupng from "./assets/img/instructions/mainmenu.png"
import submenupng from "./assets/img/instructions/submenu.png"
import subsubmenupng from "./assets/img/instructions/subsubmenu.png"
import learnpng from "./assets/img/instructions/learn.png"
import backpng from "./assets/img/instructions/back.png"
import practicepng from "./assets/img/instructions/practice.png"
import writepng from "./assets/img/instructions/write.png"
import learnplaypng from "./assets/img/instructions/learnplay.png"
import learnwritepng from "./assets/img/instructions/learnwrite.png"
import selectlearnwritepng from "./assets/img/instructions/selectlearnwrite.png"
import selectautoplaypng from "./assets/img/instructions/selectautoplay.png"
import selectpracticelistpng from "./assets/img/instructions/selectpracticelist.png"
import addpracticelistpng from "./assets/img/instructions/addpracticelist.png"

type InstructionSection = {
  title: string;
  content: string;
  contente?: string;
  heading: string;
  image?: any;
  image2?: any;
};

const UsageInstructions: React.FC = () => {
  const navigate = useNavigate();
  const sections: InstructionSection[] = [
    {
      heading: '主',
      title: '主選單 (Main Menu)',
      content: '這是應用的起點。選擇不同科目後會進入子選單。',
      contente: 'This is the starting point of the application. Selecting a different school year/topic will navigate to a sub-menu.',
      image: mainmenupng,
    },
    {
      heading: '子',
      title: '子選單 (Submenu)',
      content: '選擇主選單項目後，進入特定主題的次級選單，包含更細分的學習單元。',
      contente: 'After selecting from the main menu, you will navigate to a sub-menu containing more specific study units.',
      image: submenupng,
    },
    {
      heading: '子子',
      title: '子子選單 (Subsubmenu)',
      content: '在子選單中選擇特定單元後，進入最終學習材料選擇層級。在按鈕左側僅選擇這課程。在按鈕右側，選擇這課程和之前的所有課程。',
      contente: 'After selecting a specific unit in the submenu, you will enter the learning material selection level. The left side of each button only selects a single lesson. The right side selects a lesson and all previous lessions together.',
      image: subsubmenupng,
    }
  ];

  const cardsections: InstructionSection[] = [
    {
      heading: '學',
      title: '模式：學習抽認卡片 (Mode: Learn)',
      content: '學習材料以可翻轉的口語抽認卡的形式呈現。',
      contente: 'The learning materials are presented in the form of flippable speaking flashcards.',
      image: learnpng,
      image2: backpng,
    },
    {
      heading: '練',
      title: '模式：練習抽認卡片 (Mode: Practice Reading)',
      content: '透過練習看看你是否記得字符的發音。',
      contente: 'Practice and see if you can remember the pronunciation of the characters.',
      image: practicepng,
    },
    {
      heading: '寫',
      title: '模式：練習寫字 (Mode: Practice Writing)',
      content: '透過練習看看你能不能記住如何寫字。',
      contente: 'Practice and see if you can remember how to write the characters.',
      image: writepng,
    },
    {
      heading: '學寫',
      title: '試著寫 (Try Writing)',
      content: '從學習模式中，可以試著寫字。',
      contente: 'In the Learn mode, you can try writing the characters.',
      image: selectlearnwritepng,
      image2: learnwritepng,
    },    
    {
      heading: '自動',
      title: '自動播放 (Autoplay)',
      content: '從學習和練習模式中,可以自動播放抽認卡並調整速度。',
      contente: 'In the Learn and Practice modes, you can play flashcards automatically and adjust the speed.',
      image: selectautoplaypng,
      image2: learnplaypng,
    },    
    {
      heading: '複習',
      title: '複習表 (Review List)',
      content: '可以將卡片加到複習表中，然後從任何選單開啟複習表。',
      contente: 'You can add cards to a review list and then open the review list from any menu.',
      image: addpracticelistpng,
      image2: selectpracticelistpng,
    },    
  ];


  return (
    <div className="instructions-container">
      <button className="back-to-app-button" onClick={() => navigate('/')} >
        使用說明
      </button>
      <h2> Usage Instructions</h2>

      <div className="modes-section">
        <h2>三種學習模式 (學，練，寫）</h2>
        <h4>3 Modes of Operation (Learn, Practice, Write)</h4>
        <div className="mode-header">
          <img src={modepng} style={{width: '330px'}} alt="圖片" />          
        </div>
      </div>

      <div className="navigation-sections">
        {sections.map((section, index) => (
          <div key={section.title} className="instruction-card">
            <div className="step-number">{section.heading}</div>
            <h3>{section.title}</h3>
            <p>{section.content}</p>
            <p><small>{section.contente}</small></p>
            <img src={section.image} style={{width: '330px'}} alt="" />          
          </div>
        ))}
      </div>


      <div className="navigation-sections">
        {cardsections.map((section, index) => (
          <div key={section.title} className="instruction-card">
            <div className="step-number">{section.heading}</div>
            <h3>{section.title}</h3>
            <p>{section.content}</p>
            <p><small>{section.contente}</small></p>
            <img src={section.image} style={{width: '330px'}} alt="" />
            { section.image2 && 
              <div>
                <img src={section.image2} style={{width: '330px', paddingTop: '40px'}} alt="" />
              </div>
            } 
          </div>
        ))}
      </div>


      <div className="footer-note">

        <button className="back-to-app-button" onClick={() => navigate('/')} >
          返回
        </button>
      </div>
    </div>
  );
};

export default UsageInstructions;
