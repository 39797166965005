// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentSet, setCurrentMode, reindexCards, buttonColors, 
         toggleRandomState, toggleWriteState,
         updateScrollPosition, shuffleAndModifyKeys,
         setPublisher, setYear, setSet, setSubset,
         initializeSpeech, setSpeakerState } from './actions';
import { persistor } from './store';

import "./AppStyles.css";
import "./MenuStyles.css";

import { SpeakerXMarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HomeIcon, StarIcon as StarFillIcon, 
         SpeakerWaveIcon, ArrowsRightLeftIcon} from '@heroicons/react/24/solid'; 
import { ArrowsCrossingIcon } from "@sidekickicons/react/24/solid";


import Cards from "./Cards";

export default function MainMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state: any) => state.app.userList);

  const randomState = useSelector((state: any) => state.app.randomState);
  const writeState = useSelector((state: any) => state.app.writeState);
  const scrollPosition = useSelector((state: any) => state.app.scrollPosition);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  const selectedPublisher = useSelector((state: any) => state.app.publisher); // Get current Publisher from state
  const currentMode = useSelector((state: any) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: any) => state.app.modes); // Get modes array from state
  const appNameButtonColor = (currentMode == "練") ? 'var(--color-練)' : (currentMode == "寫") ? 'var(--color-寫)' : 'var(--color-學)';

  const filteredData = {
      ...flashcarddata[selectedPublisher],
      ...flashcarddata['無']
    };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // useLayoutEffect activates before the next component renders
  useLayoutEffect(() => {
  	window.scrollTo(0, scrollPosition);
    return () => {
      dispatch(updateScrollPosition(window.scrollY));
    };
  }, [scrollPosition, dispatch]);

  useEffect(() => {
    if (currentMode === "寫") {
      if (!writeState) {
        dispatch(toggleWriteState());
      }
    } else if (currentMode === "練") {    
      if (writeState) {
        dispatch(toggleWriteState());
      }
    } else {
      if (writeState) {
        dispatch(toggleWriteState());
      }
    }
  }, [currentMode, writeState]);  // Watch these states for changes


  // Set the current set to the user-defined list
  const selectUserList = () => {
    // initialize speechSynthesis with user interaction
    initializeSpeech();

    let carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
    dispatch(setSet("MainMenu"));    
    dispatch(setSubset("MainMenu"));   
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  const handleWriteChange = () => {
    dispatch(toggleWriteState());
  };

  function returnHome() {
    navigate("/");
  }

	const handlePurgeLocalStorage = () => {
	  persistor.purge();
	  // Optionally, you can reload the page to reflect the changes
	  window.location.reload();
	};

  const menuOptions = Object.keys(filteredData);
  // calculate button height size based on number of options
  const menuButtonHeight = Math.min( 150, 300 / Math.ceil( menuOptions.length / 2 ) );

  // calculate button font size based on max length of text of options
  const menuKeyLengths = menuOptions.map(key => key.length);
  const maxLength = Math.max(...menuKeyLengths);
  const menuFontSize = (maxLength < 5) ? "24px" : (maxLength == 5) ? "24px" : (maxLength == 6) ? "20px" : "18px";

  const handleMenuClick = (option: any) => {
    // initialize speechSynthesis with user interaction
    initializeSpeech();
    dispatch(setYear(option));
    navigate('/SubMenu')
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }

  const handleModeChange = () => {
    dispatch(setCurrentMode());
  };

  const handlePublisherChange = (option: any) => {
    dispatch(setPublisher(option));
  };


  return (
  	<div className="mainmenu">
	  	<div className="menu">
        <button onClick={returnHome} className="appNameButton" style={{backgroundColor: "none"}}>練習國字</button>
      </div>

	  	<div className="topbody">
{/*
	     	<button onClick={handleWriteChange} className={`write-toggle-button ${writeState ? 'write' : 'nowrite'}`}>
          <PencilSquareIcon style={{ width: '30px', height: '30px', color: 'white' }} />
	     	</button>
*/}
        <button onClick={handleModeChange} className={`mode-toggle-button ${currentMode}`}>
          <span className="text">{currentMode}</span> {/* Display current mode */}
        </button>

		   	<button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <ArrowsCrossingIcon style={{ width: '30px', height: '30px', color: 'white' }} />
      	</button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          {speaker ? <SpeakerWaveIcon style={{ width: '30px', height: '30px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '30px', height: '30px', color: 'white' }} />}
        </button>
		   	<button onClick={selectUserList} className="userListButton">
          <StarFillIcon style={{ width: '30px', height: '30px', color: 'yellow' }} />
		   	</button>
      </div>

      <div className="basemenu">
        {menuOptions.map((option, index) => (
          <button key={index} 
          				onClick={() => handleMenuClick(option)}
                  style={{ backgroundColor: buttonColors[index % buttonColors.length],
                           height: menuButtonHeight,
                           fontSize: menuFontSize  }}>
            {option}
          </button>
        ))}
      </div>

	   	<div className="footer">

        <button className="usage-button"
                onClick={() => navigate('/UsageInstructions')} >
          說明
        </button>

        <div className="publisher-dropdown">
          <button 
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`publisher-dropdown-button ${selectedPublisher}`}
          >
            <span className="text">{selectedPublisher}</span>
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <div 
                className="menu-item"
                onClick={() => {
                  handlePublisherChange('康軒');
                  setIsDropdownOpen(false);
                }}
              >
                康軒
              </div>
              <div 
                className="menu-item"
                onClick={() => {
                  handlePublisherChange('南一');
                  setIsDropdownOpen(false);
                }}
              >
                南一
              </div>
              <div 
                className="menu-item"
                onClick={() => {
                  handlePublisherChange('翰林');
                  setIsDropdownOpen(false);
                }}
              >
                翰林
              </div>
            </div>
          )}
        </div>

{/*
        <button onClick={handlePublisherChange} className={`publisher-toggle-button ${selectedPublisher}`}>
          <span className="text">{selectedPublisher}</span> 
        </button>
*/}
		  	<small>
		  		秀朗國小-補校
		  		<br/>
		  		App 2.1.0 創作者：劉喆
		  	</small>
			</div>

		</div>
  )
}
