// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import "./AppStyles.css";

import Cards from "./Cards";
import MainMenu from "./MainMenu";
import LoadingPage from "./LoadingPage"
import SubMenu from "./SubMenu"
import SubSubMenu from "./SubSubMenu"
import UsageInstructions from "./UsageInstructions"

import WebFont from 'webfontloader';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

function App() {

  const [isLoading, setLoading] = useState(true);
  function someRequest() : Promise<void> { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise(resolve => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  }, []);

  if (isLoading) {
    return null;
  }
  

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<LoadingPage />} />
            <Route path="/MainMenu" element={<MainMenu />} />
            <Route path="/SubMenu" element={<SubMenu />} />
            <Route path="/SubSubMenu" element={<SubSubMenu />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/*" element={<Navigate to="/MainMenu" />} />
            <Route path="/UsageInstructions" element={<UsageInstructions />} />
          </Routes>
        </Router>

      </PersistGate>
    </Provider>
  );
}

export default App;