// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, persistor } from './store';

import { reindexCards, buttonColors, toggleRandomState, toggleWriteState,
         setCurrentSet, setCurrentMode, shuffleAndModifyKeys,
         setPublisher, setYear, setSet, setSubset,
         initializeSpeech, setSpeakerState } from './actions';

import "./AppStyles.css";
import "./MenuStyles.css";

import { SpeakerXMarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HomeIcon, StarIcon as StarFillIcon, ArrowUpTrayIcon, 
         SpeakerWaveIcon, ArrowsRightLeftIcon} from '@heroicons/react/24/solid'; 
import { ArrowsCrossingIcon } from "@sidekickicons/react/24/solid";


// export default function SubMenu({ setOptions }) {
export default function SubSubMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  if (Object.keys(flashcarddata).length < 1) {
    navigate("/");   
  }

  const userList = useSelector((state: RootState) => state.app.userList);
  const randomState = useSelector((state: RootState) => state.app.randomState);
  const writeState = useSelector((state: any) => state.app.writeState);
  const selectedPublisher = useSelector((state: RootState) => state.app.publisher); // Get current Publisher from state
  const selectedYear = useSelector((state: RootState) => state.app.year);
  const selectedSet = useSelector((state: RootState) => state.app.set);
  const speaker = useSelector((state: RootState) => state.app.speakerState) ?? true;

  const currentMode = useSelector((state: RootState) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: RootState) => state.app.modes); // Get modes array from state

  const filteredData = {
      ...flashcarddata[selectedPublisher],
      ...flashcarddata['無']
    };

  const menuOptions = Object.keys(filteredData[selectedYear][selectedSet]);
  const appNameButtonColor = (currentMode == "練") ? 'var(--color-練)' : (currentMode == "寫") ? 'var(--color-寫)' : 'var(--color-學)';

  useEffect(() => {
    if (selectedSet == null) {
      navigate("/");
    }
  }, [selectedSet, navigate]);


  useEffect(() => {
    if (currentMode === "寫") {
      if (!writeState) {
        dispatch(toggleWriteState());
      }
    } else if (currentMode === "練") {    
      if (writeState) {
        dispatch(toggleWriteState());
      }
    } else {
      if (writeState) {
        dispatch(toggleWriteState());
      }
    }
  }, [currentMode, writeState]);  // Watch these states for changes


//-----------------------------------------
//
//  Select Set
//
//-----------------------------------------

  const selectSet = (event: React.MouseEvent<HTMLButtonElement>, subsetName: string) => {
    initializeSpeech();
    // const dataset = (flashcarddata[setName as keyof typeof flashcarddata] as any)[subsetName];
    let dataset;

    // Calculate the vertical click position relative to the button
    const button = event.currentTarget;
    // const buttonHeight = button.offsetHeight;
    const buttonWidth = button.offsetWidth;

    // const clickPosition = event.clientY - button.getBoundingClientRect().top; // Position within the button
    const clickPosition = event.clientX - button.getBoundingClientRect().left;

    if (selectedYear != "其他") {
      if (clickPosition > buttonWidth / 2) {
        
        // Accumulate all subsets up to the clicked one
        const allSubsets = menuOptions;
        const clickedSubsetIndex = allSubsets.indexOf(subsetName);
        const cumulativeSubsets = allSubsets.slice(0, clickedSubsetIndex + 1);

        // Combine datasets of the subsets up to the clicked one
        dataset = cumulativeSubsets.reduce<any[]>((acc, subset) => {
          // Add the current subset data to the accumulator
          return [...acc, ...(filteredData[selectedYear][selectedSet][subset] || [])];
        }, []);
        
      } else {
        // If clicked in the top half, just use the clicked subset
        dataset = filteredData[selectedYear][selectedSet][subsetName] || [];
      }
    } else {
      dataset = filteredData[selectedYear][selectedSet][subsetName] || [];
    }

    if (dataset.length > 0) {
      const carddata = randomState ? shuffleAndModifyKeys(dataset) : dataset;
      dispatch(setCurrentSet(carddata));
      dispatch(setSubset(subsetName));
      navigate("/cards");
    } else {
      console.error(`Invalid set name: ${subsetName}`);
    }

  };

  const selectUserList = () => {
    initializeSpeech();

    const carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  const handleWriteChange = () => {
    dispatch(toggleWriteState());
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }

  function returnToMenu() {
    navigate("/SubMenu");
  }

  const handleModeChange = () => {
    dispatch(setCurrentMode());
  };

  function darkenColor(color:string, percentage:number): string {
    // Function to darken a single color component
    const darken = (value:number, percentage:number) => Math.max(0, Math.min(255, value - (value * percentage) / 100));

    let hex = color.slice(1);

    // Handle shorthand hex (e.g., #ABC => #AABBCC)
    if (hex.length === 3) {
      hex = hex.split('').map((h) => h + h).join('');
    }

    // Extract RGB components from the hex string
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Darken each component
    const newR = darken(r, percentage);
    const newG = darken(g, percentage);
    const newB = darken(b, percentage);

    // Convert the new RGB values to hex and round to the nearest integer
    const toHex = (value:number) => Math.round(value).toString(16).padStart(2, '0');

    // Return the hex color string
    return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
  }



  return (
    <div className="submenu">
      <div className="menu">
        <button onClick={returnToMenu} className="appNameButton" style={{backgroundColor: appNameButtonColor, boxShadow: "none" }}>
          <ArrowUpTrayIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
      </div>

      <div className="topbody">
{/*
        <button onClick={handleWriteChange} className={`write-toggle-button ${writeState ? 'write' : 'nowrite'}`}>
          <PencilSquareIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
*/}
        <button onClick={handleModeChange} className={`mode-toggle-button ${currentMode}`}>
          <span className="text">{currentMode}</span> {/* Display current mode */}
        </button>

        <button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <ArrowsCrossingIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          {speaker ? <SpeakerWaveIcon style={{ width: '30px', height: '30px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '30px', height: '30px', color: 'white' }} />}
        </button>
        <button onClick={selectUserList} className="userListButton">
          <StarFillIcon style={{ width: '30px', height: '30px', color: 'yellow' }} />
        </button>
      </div>

      <div className="nav3fr">
        {selectedSet && menuOptions && 
          menuOptions.map((subsetName,index) => {
            const topColor = buttonColors[index % buttonColors.length];
            const bottomColor = darkenColor(topColor, 15);  // Darken the color for the bottom half

            if (selectedYear != "其他") {
              return (
                <button
                  key={subsetName}
                  onClick={(event) => selectSet(event, subsetName)}
                  style={{ background: `linear-gradient(to right, ${topColor} 50%, ${bottomColor} 50%)`}}
                >
                  {subsetName}
                </button>
              );
            } else {
              return (
                <button
                  key={subsetName}
                  onClick={(event) => selectSet(event, subsetName)}
                  style={{ background: `${topColor}` }}
                >
                  {subsetName}
                </button>
              );
            }
          })
        }
      </div>
    </div>
  );
}
