// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, persistor } from './store';

import { reindexCards, buttonColors, toggleRandomState, toggleWriteState,
         setCurrentSet, setCurrentMode, shuffleAndModifyKeys,
         setPublisher, setYear, setSet, setSubset,
         initializeSpeech, setSpeakerState } from './actions';

import "./AppStyles.css";
import "./MenuStyles.css";

import { SpeakerXMarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HomeIcon, StarIcon as StarFillIcon, 
         SpeakerWaveIcon, ArrowsRightLeftIcon} from '@heroicons/react/24/solid'; 
import { ArrowsCrossingIcon } from "@sidekickicons/react/24/solid";


// export default function SubMenu({ setOptions }) {
export default function SubMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  if (Object.keys(flashcarddata).length < 1) {
    navigate("/");   
  }

  const userList = useSelector((state: RootState) => state.app.userList);
  const randomState = useSelector((state: RootState) => state.app.randomState);
  const writeState = useSelector((state: RootState) => state.app.writeState);
  const selectedPublisher = useSelector((state: RootState) => state.app.publisher); // Get current Publisher from state
  const selectedYear = useSelector((state: RootState) => state.app.year);
  const speaker = useSelector((state: RootState) => state.app.speakerState) ?? true;

  const currentMode = useSelector((state: RootState) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: RootState) => state.app.modes); // Get modes array from state

  console.log(selectedPublisher);
  const filteredData = {
      ...flashcarddata[selectedPublisher],
      ...flashcarddata['無']
    };

  const menuOptions = Object.keys(filteredData[selectedYear]);
  const menuButtonHeight = Math.min( 150, 300 / Math.ceil( menuOptions.length / 2 ) );
  const menuKeyLengths = menuOptions.map(key => key.length);
  const maxLength = Math.max(...menuKeyLengths);
  const menuFontSize = (maxLength < 4) ? "24px" : (maxLength == 4) ? "24px" : (maxLength == 5) ? "20px" : "18px";

  const appNameButtonColor = (currentMode == "練") ? 'var(--color-練)' : (currentMode == "寫") ? 'var(--color-寫)' : 'var(--color-學)';

  useEffect(() => {
    if (selectedYear == null) {
      navigate("/");
    }
  }, [selectedYear, navigate]);


  useEffect(() => {
    if (currentMode === "寫") {
      if (!writeState) {
        dispatch(toggleWriteState());
      }
    } else if (currentMode === "練") {    
      if (writeState) {
        dispatch(toggleWriteState());
      }
    } else {
      if (writeState) {
        dispatch(toggleWriteState());
      }
    }
  }, [currentMode, writeState]);  // Watch these states for changes


//-----------------------------------------
//
//  Select menu option
//
//-----------------------------------------


  const handleMenuClick = (option: any) => {
    // initialize speechSynthesis with user interaction
    initializeSpeech();
    dispatch(setSet(option));
    navigate('/SubSubMenu')
  };


  const selectUserList = () => {
    initializeSpeech();

    const carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
//    dispatch(setCurrentSubMenu("MainMenu"));  // remove this so up navigates back to submenu
    dispatch(setSet("SubMenu"));    
    dispatch(setSubset("SubMenu"));   
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  const handleWriteChange = () => {
    dispatch(toggleWriteState());
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }

  function returnHome() {
    navigate("/");
  }

  const handleModeChange = () => {
    dispatch(setCurrentMode());
  };


  return (
    <div className="submenu">
      <div className="menu">
        <button onClick={returnHome} className="appNameButton" style={{backgroundColor: appNameButtonColor, boxShadow: "none" }}>
          <HomeIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
      </div>

      <div className="topbody">
{/*
        <button onClick={handleWriteChange} className={`write-toggle-button ${writeState ? 'write' : 'nowrite'}`}>
          <PencilSquareIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
*/}
        <button onClick={handleModeChange} className={`mode-toggle-button ${currentMode}`}>
          <span className="text">{currentMode}</span> {/* Display current mode */}
        </button>

        <button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <ArrowsCrossingIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          {speaker ? <SpeakerWaveIcon style={{ width: '30px', height: '30px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '30px', height: '30px', color: 'white' }} />}
        </button>
        <button onClick={selectUserList} className="userListButton">
          <StarFillIcon style={{ width: '30px', height: '30px', color: 'yellow' }} />
        </button>
      </div>


      <div className="basemenu">
        {menuOptions.map((option, index) => (
          <button key={index} 
                  onClick={() => handleMenuClick(option)}
                  style={{ backgroundColor: buttonColors[index % buttonColors.length],
                           height: menuButtonHeight,
                           fontSize: menuFontSize  }}>
            {option}
          </button>
        ))}
      </div>


    </div>
  );
}
