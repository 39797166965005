// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ReactSketchCanvas, CanvasPath } from 'react-sketch-canvas';
import { addToUserList, removeFromUserList, initializeSpeech, setIsPlaying,
         setSpeakerState, setCardSide, setCanvasVisibility } from './actions';

import { StarIcon, SpeakerXMarkIcon,EyeSlashIcon, DocumentCheckIcon,
        ArrowTopRightOnSquareIcon, XMarkIcon, TrashIcon, BackspaceIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarFillIcon, SpeakerWaveIcon, EyeIcon, 
         PencilIcon, PlayIcon} from '@heroicons/react/24/solid'; 

export default function Card({ card }: any) {

  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [allVoices, setAllVoices] = useState<SpeechSynthesisVoice[]>([]);  // Explicitly type as SpeechSynthesisVoice[]

  const userList = useSelector((state: any) => state.app.userList);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  let side = useSelector((state: any) => state.app.cardSide) ?? false;
  const writeState = useSelector((state: any) => state.app.writeState);
  const currentMode = useSelector((state: any) => state.app.currentMode); // Get current mode from state

  const prevSide1 = useRef(card.fields.side1);
  const currentAudioRef = useRef<HTMLAudioElement | null>(null);
  const audioCurrentTimeRef = useRef<number>(0);  // Track the current time using useRef

  const [isCanvasVisible, setIsCanvasVisible] = useState(writeState);  // Track canvas visibility
  const [canvasContent, setCanvasContent] = useState<CanvasPath[] | null>(null);  // Allow string or null
  const canvasRef = useRef<any>(null);  // Ref to interact with the canvas
  const [strokeCount, setStrokeCount] = useState(0);  // Track the number of strokes
  const isPlaying = useSelector((state: any) => state.app.isPlaying);

  const handleStrokeChange = (updatedPaths: CanvasPath[]) => {
    setStrokeCount(updatedPaths.length);  // Update stroke count with the number of paths
  };

//------------------------------------------------------------
//
//          Triggers
//
//------------------------------------------------------------  

  useEffect(() => {
    if (canvasContent && canvasRef.current && !side) {
      // Restore the canvas content (if any) when the component re-renders
      canvasRef.current.loadPaths(canvasContent);
    }
  }, [side]);


  useEffect(() => {
    if (prevSide1.current !== card.fields.side1) {
      // Trigger the transition when side1 changes
      prevSide1.current = card.fields.side1;
    }
    setIsCanvasVisible(writeState); 
    if (writeState && !speaker) {
      dispatch(setSpeakerState());    
    }
  }, [card.fields.side1]);


  useEffect(() => {
    // Effect cleanup: If the component unmounts, stop any playing audio
    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
      }
    };
  }, []);  // Empty dependency array to ensure this only runs on mount/unmount


  useEffect(() => {
    // Check if the card is in the userList and update isAdded accordingly
    const cardIsAdded = userList.some((userCard: any) => userCard.fields.side1 === card.fields.side1);
    setIsAdded(cardIsAdded);
  }, [userList, card]);


  const side1Length = card.fields.side1.length;
  const side2Length = card.fields.side2.length;
  // const maxLength = Math.max(side1Length, side2Length);


  const getVoices = (): Promise<SpeechSynthesisVoice[]> => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices();
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices()
        resolve(voices)
      }
    })
  }


useEffect(() => {
    const loadVoices = async () => {
      const loadedVoices: SpeechSynthesisVoice[] = await getVoices();
      setAllVoices(loadedVoices);
      // console.log(loadedVoices);
      setVoicesLoaded(true);
    };
    loadVoices();
  }, []);

//------------------------------------------------------------
//
//          Main speech/audio function
//
//------------------------------------------------------------  

  const speakTTS = async () => {

    speechSynthesis.cancel();

    // speechPattern 1: frontphrase = side2phrase + 的 + side1phrase

    if (currentAudioRef.current) {
      // console.log("after checking current: ",audioCurrentTimeRef);
      return;
    }

    if (!allVoices || allVoices.length === 0) {
      console.error("No voices available!");
      return; // Exit if no voices are available
    }

    let frontphrase = '';
    let backphrase = '';

    let side1phrase = card.fields.side1p ? card.fields.side1p : card.fields.side1;
    let side2phrase = card.fields.side2p ? card.fields.side2p : card.fields.side2b;
        
    const cardType = card.type ? card.type : "字";
    const cardlang = card.lang ? card.lang : "zh-TW";

    if ( cardType == "部首" ) {
      if (currentMode == '學') {
        frontphrase = side1phrase + "字部";
        backphrase = side2phrase;
      } else if (currentMode == '練') {
        frontphrase = ""
        backphrase = side2phrase;
      } else {
        frontphrase = side1phrase + "字部";
        backphrase = side2phrase;
      } 
    } else if ( cardType == "vocab" ) {
      frontphrase = side1phrase;
      backphrase = side2phrase;
    } else if ( cardType == "成語" ) {
      if (currentMode == '學') {
        frontphrase = side1phrase;
        backphrase = side2phrase;
      } else if (currentMode == '練') {
        frontphrase = ""
        backphrase = side1phrase;
      } else {
        frontphrase = side1phrase;
        backphrase = side1phrase;
      } 
    } else if ( cardType == "詞語" ) {
      if (currentMode == '學') {
        frontphrase = side1phrase;
        backphrase = side2phrase;
      } else if (currentMode == '練') {
        frontphrase = ""
        backphrase = side2phrase;
      } else {
        frontphrase = side1phrase;
        backphrase = side2phrase;
      }
    } else if ( cardType == "假日" ) {
      if (currentMode == '學') {
        frontphrase = side2phrase + "的" + side1phrase;
        backphrase = side1phrase;
      } else if (currentMode == '練') {
        frontphrase = ""
        backphrase = side1phrase;
      } else {
        frontphrase = side1phrase;
        backphrase = side1phrase;
      }
    } else {   // 中文字
      if (currentMode == '學') {
        frontphrase = side2phrase + "的" + side1phrase;
        backphrase = side1phrase;
      } else if (currentMode == '練') {
        frontphrase = ""
        backphrase = side2phrase + "的" + side1phrase;
      } else {
        frontphrase = side2phrase + "的" + side1phrase;
        backphrase = side1phrase;
      }
    }

    if (cardlang.endsWith(".mp3")) {
    const mp3name = !side ? cardlang.split(".")[0] : `${cardlang.split(".")[0]}b`;
    import(`./assets/mp3/${mp3name}.mp3`)
      .then((audioModule) => {
        // Now, audioModule will contain the dynamically imported module
        let audio = new Audio(audioModule.default);
        // console.log("new audio ref created");
        currentAudioRef.current = audio;
        audio.play();
      })
      .catch((error) => {
        console.error("Error loading audio file:", error);
      });
    }
    else {
      const message = new SpeechSynthesisUtterance();

      const voiceMap = new Map<string, string[]>([
        ['zh-TW', ['Meijia', 'Mei-Jia']], // Both Meijia and Mei-Jia are possible
        ['zh-CN', ['Tingting']],
        ['de-DE', ['Anna']],
        ['es-ES', ['Mónica']],
        ['ja-JP', ['Kyoko']],
        ['en-US', ['Samantha']],  // Default voice
      ]);
      const selectedVoiceNames = voiceMap.get(cardlang) || ['Samantha'];  // Default to 'Samantha' if not found
      let voice = null;

      for (const voiceName of selectedVoiceNames) {
        voice = allVoices.find(v => v.name === voiceName);
        if (voice) {
          break;  // Stop once we find the matching voice
        }
      }

      if (voice) {
        message.voice = voice;
      } else {
        console.warn(`Voice for ${cardlang} not found, using default voice`);
      }

      message.text = !side ? frontphrase : backphrase ;
      message.volume = 1; // Volume range = 0 - 1
      message.rate = 0.8; // Speed of the text read , default 1
      message.lang = cardlang; // Language, default 'zh-TW'
      speechSynthesis.speak(message);
    }
  }

  useEffect(() => {
    if (voicesLoaded && speaker) {
      speakTTS();
    }
  }, [voicesLoaded, speaker, side, card.fields.side1]);


//------------------------------------------------------------
//
//          Handlers
//
//------------------------------------------------------------  

  function handleClick() {
    if (!isCanvasVisible) {
      setIsCanvasVisible(writeState); 
      dispatch(setCardSide(!side));
    }
  }

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());    

    // console.log("currentAudioRef.current",currentAudioRef.current);
    if (currentAudioRef.current) {
      // console.log("speaker",speaker);
      if (speaker ) {
        audioCurrentTimeRef.current = currentAudioRef.current.currentTime;
        // console.log("Pausing at ",audioCurrentTimeRef.current);
        currentAudioRef.current.pause();        
      } else {
        currentAudioRef.current.currentTime = audioCurrentTimeRef.current;
        // console.log("resuming at ",audioCurrentTimeRef.current);
        currentAudioRef.current.play();        
      }
    }
  }

  function handleReplayClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (!speaker) {
      dispatch(setSpeakerState());
    } else {
      speakTTS();
    }
  }


  function handleAddRemoveClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (isAdded) {
      dispatch(removeFromUserList(card));
    } else {
      dispatch(addToUserList(card));
    }
    setIsAdded(!isAdded);
  }

  const handleToggleCanvas = (event: React.MouseEvent) => {
    event.stopPropagation();   // Prevent card flip
    const newVisibility = !isCanvasVisible;
    setIsCanvasVisible(newVisibility);
    dispatch(setCanvasVisibility(newVisibility)); // Update Redux state
};

//------------------------------------------------------------
//
//          Canvas Handlers
//
//------------------------------------------------------------  

const handleUndo = async () => {
  if (canvasRef.current) {
    // Save current drawing paths to canvasContent before undoing
    const currentPaths = await canvasRef.current.exportPaths();

    // Remove the last path from canvasContent
    if (currentPaths.length > 0) {
      currentPaths.pop();  // Remove the last path
    }

    // Clear the canvas and redraw the remaining paths
    canvasRef.current.clearCanvas();
    canvasRef.current.loadPaths(currentPaths);
  }
};


  // Clear the canvas
const handleClearCanvas = () => {
  if (canvasRef.current) {
    canvasRef.current.clearCanvas();  // Clear the drawing
    setCanvasContent([]);  // Clear the stored content as well
  }
};


const handleSaveCanvas = () => {
  if (canvasRef.current) {
    canvasRef.current.exportPaths().then((dataPaths: CanvasPath[]) => {

      // Store the canvas paths
      setCanvasContent(dataPaths);

      // Ensure 'card' and 'side1' exist and are valid
      const side1 = card?.fields?.side1;
      if (!side1) {
        console.error('Side1 is missing!');
        return;
      }

      const canvasWidth = (document.getElementsByClassName("canvas-container")[0] as HTMLElement).offsetWidth || 350;
      const canvasHeight = (document.getElementsByClassName("canvas-container")[0] as HTMLElement).offsetHeight || 280;

      // Create the payload object that will be sent to the API
      const dataToSend = {
        word: side1,             // The word (from side1 of the flashcard)
        width: canvasWidth,
        height: canvasHeight,
        svgPaths: dataPaths,     // The SVG paths from the canvas
        timestamp: new Date().toISOString(), // Current timestamp in ISO format
        deviceInfo: navigator.userAgent, // Browser/Device information
      };

      // Convert the payload to JSON format
      const jsonData = JSON.stringify(dataToSend);

      // Optionally, send the data via an API call
      sendCanvasDataToAPI(jsonData);

      // Close the canvas after saving the data
      setIsCanvasVisible(false);
      dispatch(setCardSide(!side));
    });
  }
};


const sendCanvasDataToAPI = async (jsonData: string) => {
  try {
    const response = await fetch('https://flashcardbackend.vercel.app/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonData,  // Send the JSON data as the request body
    });

    if (!response.ok) {
      throw new Error('Failed to send data to the API');
    }

    // Parse the JSON response (which is expected to be an object with a "correct" field)
    const result = await response.json();

    if (result.correct) {
      // Handle the success response
//      console.log('Data successfully stored!');
    } else {
      console.error('Unexpected response:', result);
    }

  } catch (error) {
    console.error('Error sending canvas data to API:', error);
  }
};


//------------------------------------------------------------
//
//          Helper functions
//
//------------------------------------------------------------  

  const getFontSize = () => {
    if (side1Length > 3) {
      return "small-font";
    } else if (side1Length > 2) {
      return "medium-font";
    } else {
      return "large-font";
    }
  };


  // Font class based on cardType

  const getFontFamilyFrontSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined
    switch (cardType) {
      case "vocab":
        return "serif";
      default:
        return "frontfont";
    }
  };


  const getFontFamilyBackSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined

    switch (cardType) {
      case "注音":
        return "frontfont";
      case "vocab":
        return "serif";
      default:
        return "backfont";
    }
  };


  const getFontSizeBackSideLower = () => {
    const cardType = card.type || "字"; 
    const side2blen = card.fields.side2b.length;
    const fontsize = (side2blen < 6) ? "40px" : (side2blen == 6) ? "32px" : (side2blen == 7) ? "24px" : "18px"
    return fontsize;
  };


//------------------------------------------------------------
//
//          Rendering
//
//------------------------------------------------------------  


  return (
    <div className={`card ${side ? "side" : ""}`} onClick={handleClick} 
    style={{ background: (!side && currentMode === "練") ? 'var(--grad-練)' : 
                         (!side && currentMode === "寫") ? 'var(--grad-寫)' : undefined }}
    >
      <div>

        {!isCanvasVisible &&
          <div className="card-top">
            <button onClick={handleAddRemoveClick} className="add-remove-button">
              {!isAdded ? <StarIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <StarFillIcon style={{ width: '36px', height: '36px', color: 'yellow' }} />}
            </button>      
            {!isPlaying && <button onClick={handleToggleCanvas} className="canvas-button">
              {!side && (currentMode != "練") && (<PencilIcon style={{ width: '32px', height: '32px', color: 'white' }} /> )}
            </button>}
            <button onClick={handleSpeakerClick} className="pronounce-button">
              {speaker ? <SpeakerWaveIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
            </button>
          </div>
        }

  
        {isCanvasVisible &&
          <div className="card-top">

            <button className="stroke-counter" style={{color: (card.fields.strokes && card.fields.strokes - strokeCount < 0) ? 'red' : 'bisque'}}>
              {card.fields.strokes ? card.fields.strokes - strokeCount : strokeCount}
            </button>

            {/* Left Button: Toggle Canvas Visibility */}
            <button onClick={handleClearCanvas} className="canvas-control-button">
                <TrashIcon style={{ width: '36px', height: '36px', color: 'white' }} />
            </button>

            <button onClick={handleUndo} className="undo-button">
              <BackspaceIcon style={{ width: '36px', height: '36px', color: 'white' }} />
            </button>

            <button onClick={handleReplayClick} className="pronounce-button">
              {speaker ? <SpeakerWaveIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
            </button>

            {/* Right Button: Save Canvas as PNG and Close */}
            <button onClick={handleSaveCanvas} className="canvas-control-button">
                <DocumentCheckIcon style={{ width: '36px', height: '36px', color: 'white' }} />
            </button>
          </div>
        }

      </div>

      {/* Conditionally render the React Sketch Canvas */}
      {isCanvasVisible && (
        <div className="canvas-container">
          <ReactSketchCanvas
            id="mycanvas"
            ref={canvasRef}
            width="100%"
            height="300px"
            strokeColor="black"  // Drawing color
            strokeWidth={6}      // Width of the strokes
            canvasColor="white"  // Canvas background color
            onChange={handleStrokeChange}  // Track strokes
          />
        </div>
      )}

{/*      {isCanvasVisible && (
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            fontSize: '36px',
            fontFamily: 'math',
            color: (card.fields.strokes && card.fields.strokes - strokeCount < 0) ? 'red' : 'grey',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          {card.fields.strokes ? card.fields.strokes - strokeCount : strokeCount}
        </div>
      )}
*/}
      {!isCanvasVisible && 
        <div className={`front ${getFontSize()}`} style={{ fontFamily: getFontFamilyFrontSide()}}>{card.fields.side1}
        </div>
      }

      {/* Card Back side */}
      {!isCanvasVisible && 
        <div className={`back ${getFontSize()} ${getFontFamilyBackSide()}`}>
          <div>{card.fields.side2}</div>
          { (card.type != "vocab") && <div className="fontFamily: doublefont">{card.fields.side2}</div> }
          <div className="bottomalign" style={{ fontFamily: getFontFamilyBackSide(), fontSize: getFontSizeBackSideLower() }}><hr></hr>{card.fields.side2b}</div>
        </div>
      }
    </div>
  );
}

